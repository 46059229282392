import React, { useEffect, useState } from "react";
import NewWindow from "react-new-window";
import axios from "axios";
import logo from "./logo.svg";
import "./App.css";

function App() {
  const [showWindow, setShowWindow] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    console.log("hi");
  }, []);

  function handleFetch() {
    let url = "https://api.flashbot.co/connect/mixcart.myshopify.com";
    fetch(url, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleAxios() {
    let url = "https://api.flashbot.co/connect/mixcartdev.myshopify.com";
    axios
      .get(url)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(":(");
      });
  }
  return (
    <div className="App">
      <h1>Fetch Test (Fetch API)</h1> <br></br>
      <button onClick={handleFetch}>Toggle</button>
      <h1>Fetch Test (Fetch Axios)</h1> <br></br>
      <button onClick={handleAxios}>Toggle</button>
    </div>
  );
}

export default App;
